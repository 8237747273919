import React, { useEffect, useState, useRef, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../../components/seo"
import IntroPageWrapper from "../../components/Contents/IntroPageWrapper"
import Transition from "../../components/Animation/Transition"
import { moveCameraToLayer } from "../../components/Animation/CameraAnimation"
import ThemeContext from "../../components/Map/ThemeContext"

export default function Karte({ data, location }) {
  const sendActionToStore = useDispatch()

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state ? location.state.direction : "fade"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)

  const LOCATIONS_ACTIVE = useSelector(state => state.locationsActive)

  const { portal, setPortal } = useContext(ThemeContext)
  const portalRef = useRef()

  useEffect(() => {
    // * Clear possible markers
    sendActionToStore({
      type: "change/projectScene",
      payload: {
        topVisible: false,
        bottomVisible: false,
      },
    })

    // * Portal Ref
    setPortal(portalRef)

    // * Activate Locations
    sendActionToStore({
      type: "change/locationsActive",
      payload: true,
    })

    // * BG Color to white
    sendActionToStore({
      type: `change/map/groundColor`,
      payload: "#edf2f7",
    })

    // * Move camera
    moveCameraToLayer(sendActionToStore, "karte")

    // * Unmount
    return () => {
      sendActionToStore({
        type: "change/locationsActive",
        payload: false,
      })
    }
  }, [])

  useEffect(() => {
    // * Move camera
    if (LOCATIONS_ACTIVE) {
      moveCameraToLayer(sendActionToStore, "karte")
    }
  }, [LOCATIONS_ACTIVE, sendActionToStore])

  useEffect(() => {
    if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
      setAnimationDirection(TRANSITION_INFO.direction)
      setExit(TRANSITION_INFO.shouldExit)
      sendActionToStore({
        type: `PageChangeTransition/ended`,
      })
    }
  }, [TRANSITION_INFO, sendActionToStore])

  return (
    <>
      <SEO title="Home" />
      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <IntroPageWrapper>
          <div ref={portalRef} id="portal" />
        </IntroPageWrapper>
      </Transition>
    </>
  )
}
// export const query = graphql``
